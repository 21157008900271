<template>
  <b-modal title="SHOP 설정 XLSX Upload" size="xl" v-model="show" ok-only ok-title="닫기">
    <h5>
      현재 설정 다운로드
    </h5>
    <shop-preset v-model="form.shop"></shop-preset>

    <div class="mt-2">
      <b-btn class="mr-2" variant="outline-success" @click="downXlsx('mdId')">MD ID</b-btn>
<!--      <b-btn class="mr-2" variant="outline-success" @click="downXlsx('operatePartnerRate')">운영/파트너 할인할증</b-btn>-->
      <b-btn class="mr-2" variant="outline-success" @click="downXlsx('dynamicPricing')">Dynamic Pricing</b-btn>
      <b-btn class="mr-2" variant="outline-success" @click="downXlsx('partnerGradeSetting')">Partner Grade</b-btn>
      <b-btn class="mr-2" variant="outline-success" @click="downXlsx('catalogSetting')">catalog Setting</b-btn>
    </div>

    <hr />

    <h5>
      Xlsx Upload
    </h5>

    <b-alert variant="success" show>
      좌측, 상단에 공백 없이 아래의 샘플과 같은 형태로 업로드해 주시기 바랍니다.
    </b-alert>

    <b-tabs v-model="tabIndex">
      <b-tab title="MD ID 변경">
        <htb ref="htb0" v-model="sampleData.mdId" :config="htbConfig.mdId" height="90px"></htb>
        <b-btn variant="success" @click="() => {$refs.xlsxMdId.value = null; $refs.xlsxMdId.click()}">MD ID 변경 Xlsx Upload</b-btn>
        <input type="file" ref="xlsxMdId" data-type="mdId" style="display: none" @change="handleXlsx">
      </b-tab>
      <!--
      <b-tab title="운영/파트너 할인할증 변경">
        <div class="mb-2">
          partnerGrade(파트너 등급): (비워두기), smart, premium 중 하나로 입력해주세요.<br/>
          apply_sale_operate_rate(운영 할인할증 - Sale 가격)<br/>
          apply_newin_operate_rate(운영 할인할증 - Newin 가격): Sale 가격과 동일하게 입력해주세요<br/>
          apply_sale_partner_rate(파트너 할인할증 - Sale 가격)<br/>
          apply_newin_partner_rate(파트너 할인할증 - Newin 가격): Sale 가격과 동일하게 입력해주세요<br/>
          <br/>
          <b>할인할증이라서 음수면 할인, 양수면 할증이 됩니다.</b><br/>
          설정 변경 후 꼭 해당 SHOP 들의 가격 갱신을 진행해주세요.
        </div>
        <htb ref="htb1" v-model="sampleData.operatePartnerRate" :config="htbConfig.operatePartnerRate" height="100px"></htb>
        <b-btn variant="success" @click="() => {$refs.xlsxOperatePartnerRate.value = null; $refs.xlsxOperatePartnerRate.click()}">운영/파트너 할인할증 설정 변경 Xlsx Upload</b-btn>
        <input type="file" ref="xlsxOperatePartnerRate" data-type="operatePartnerRate" style="display: none" @change="handleXlsx">
      </b-tab>
      -->
      <b-tab title="Dynamic Pricing 설정 변경">
        <div class="mb-2">
          Dynamic Pricing 진행시 발란추천가와의 갭은 (발란추천가 - 1000 원) 후 1원 단위 버림 된 값으로 공통 적용 됩니다.<br/>
          <b-badge variant="success">추천가 달성 가능시</b-badge> -  판매기준가에 설정한 최대할인률을 적용한 가격이 (발란추천가 - 1000 원 후 1원단위 내림)보다 작거나 같을 경우<br/>
          <div class="pl-2">dpPositiveBaseRate(등급제 상시 할인율): B class 달성에 따른 차등 분담률   [ DP mode = Positive ] </div>
          <div class="pl-2">dpPositiveCondRate(등급제 비상시 할인율): Shop별로 입력된 발란분담률에 따름 [미입력시 기본 설정 값 = 50%]</div>
          <div class="pl-2">dpPositiveBalaanRate(발란 추가 할인율): 100% 분담률</div>
          <div class="pl-2">dpMinRate(최소할인율): 100% 분담률, 발란추천가보다 상품가가 낮아도 최소한 적용될 할인율</div>
          <b-badge variant="warning">추천가 달성 불가능시</b-badge> - 판매기준가에 설정한 최대할인률을 적용한 가격이  (발란추천가 - 1000 원 후 1원단위 내림) 보다 클 경우<br/>
          <div class="pl-2">dpNegativeBaseRate(등급제 상시 할인율): B class 달성에 따른 차등 분담률  [ DP mode = Negative ] </div>
          <div class="pl-2">dpNegativeCondRate(등급제 비상시 할인율): Shop별로 입력된 발란분담률에 따름 [미입력시 기본 설정 값 = 50%]</div>
          <div class="pl-2">dpNegativeBalaanRate(발란 추가 할인율): 100% 분담률</div>
          <b-badge variant="warning">추천가 없을 시</b-badge> [ DP mode = Fixed ] <br/>
          <div class="pl-2">dpfixedBaseRate(등급제 상시 할인율): B class 달성에 따른 차등 분담률</div>
          <div class="pl-2">dpfixedCondRate(등급제 비상시 할인율): Shop별로 입력된 발란분담률에 따름 [미입력시 기본 설정 값 = 50%]</div>
          <div class="pl-2">dpfixedBalaanRate(발란 추가 할인율): 100% 분담률</div>
          <br/>
          <b>할인율이라서 할인할증과 다르게 양수일 때 할인이 됩니다.</b><br/>
          설정 변경 후 꼭 해당 SHOP 들의 가격 갱신을 진행해주세요.
        </div>
        <htb ref="htb1" v-model="sampleData.dynamicPricing" :config="htbConfig.dynamicPricing" height="110px"></htb>
        <b-btn variant="success" @click="() => {$refs.xlsxDynamicPricing.value = null; $refs.xlsxDynamicPricing.click()}">Dynamic Pricing 설정 변경 Xlsx Upload</b-btn>
        <input type="file" ref="xlsxDynamicPricing" data-type="dynamicPricing" style="display: none" @change="handleXlsx">
      </b-tab>
      <b-tab title="Partner Grade 변경">
        <htb ref="htb2" v-model="sampleData.partnerGradeSetting" :config="htbConfig.partnerGradeSetting" height="110px"></htb>
        <b-btn variant="success" @click="() => {$refs.xlsxPartnerGradeSetting.value = null; $refs.xlsxPartnerGradeSetting.click()}">Partner Grade Xlsx Upload</b-btn>
        <input type="file" ref="xlsxPartnerGradeSetting" data-type="partnerGradeSetting" style="display: none" @change="handleXlsx">
      </b-tab>
      <b-tab title="카탈로그(gm) 생성 룰 변경">
        <htb ref="htb3" v-model="sampleData.catalogSetting" :config="htbConfig.catalogSetting" height="90px"></htb>
        <b-btn variant="success" @click="() => {$refs.xlsxCatalogSetting.value = null; $refs.xlsxCatalogSetting.click()}">catalog Setting 변경 Xlsx Upload</b-btn>
        <input type="file" ref="xlsxCatalogSetting" data-type="catalogSetting" style="display: none" @change="handleXlsx">
      </b-tab>
    </b-tabs>
  </b-modal>
</template>

<style scoped>

</style>

<script>
import {down, readXlsx} from '@/shared/impexp'
import htb from '@/views/modules/HotTableBase.vue'

export default {
  name: 'UploadXlsx',
  components: {htb},
  model: {prop: 'value', event: 'change'},
  props: {value: Boolean},
  data() {
    return {
      tabIndex: 0,
      form: {
        shop: []
      },
      busy: {mdId: false, operatePartnerRate: false, dynamicPricing: false},
      xlsxFields: {
        mdId: 'shop_id,md_id',
        operatePartnerRate: 'shop_id,partnerGrade,apply_sale_operate_rate,apply_newin_operate_rate,' +
          'apply_sale_partner_rate,apply_newin_partner_rate',
        dynamicPricing: 'shop_id,dynamicPricingEnabled,dpCondBalaanRatio,dpPositiveBaseRate,dpPositiveCondRate,dpPositiveBalaanRate,dpMinRate,' +
          'dpNegativeBaseRate,dpNegativeCondRate,dpNegativeBalaanRate',
        partnerGradeSetting: 'shop_id,partnerGrade',
        catalogSetting: 'shop_id,goodsHealthRating'
      },
      sampleData: {
        mdId: [
          {shop_id: 12345, md_id: 'user@balaan.co.kr'},
          {shop_id: 23456, md_id: 'notNameButId'},
        ],
        operatePartnerRate: [
          {
            shop_id: 12345, partnerGrade: 'premium', apply_sale_operate_rate: -5, apply_newin_operate_rate: -5,
            apply_sale_partner_rate: -5.7, apply_newin_partner_rate: -5.7
          },
          {
            shop_id: 23456, partnerGrade: 'normal', apply_sale_operate_rate: '', apply_newin_operate_rate: '',
            apply_sale_partner_rate: -6, apply_newin_partner_rate: -6
          },
        ],
        dynamicPricing: [
          {
            shop_id: 12345, dynamicPricingEnabled: true, dpCondBalaanRatio: 50, dpPositiveBaseRate: 5, dpPositiveCondRate: 8, dpPositiveBalaanRate: 5,
            dpNegativeBaseRate: 5, dpNegativeCondRate: 6, dpNegativeBalaanRate: 0, dpMinRate: 5
          },
          {
            shop_id: 23456, dynamicPricingEnabled: false, dpCondBalaanRatio: '', dpPositiveBaseRate: '', dpPositiveCondRate: '', dpPositiveBalaanRate: '',
            dpNegativeBaseRate: '', dpNegativeCondRate: '', dpNegativeBalaanRate: '', dpMinRate: ''
          },
        ],
        partnerGradeSetting: [
          {shop_id: 12345, partnerGrade: 'premium'},
          {shop_id: 23456, partnerGrade: 'smart'},
          {shop_id: 34567, partnerGrade: 'normal'},
          {shop_id: 45678, partnerGrade: 'none'},
        ],
        catalogSetting: [
          {shop_id: 12345, goodsHealthRating: 'B'},
        ],
      },
      validator: {
        mdId: {
          shop_id: {test: /^\d{1,5}$/, type: 'number', required: true},
          md_id: {test: /^[\w.@]+$/, type: 'string', required: true},
        },
        operatePartnerRate: {
          shop_id: {test: /^\d{1,5}$/, type: 'number', required: true},
          partnerGrade: {test: /^(none|smart|premium|normal|starter1|starter2)$/, type: 'string'},
          apply_sale_operate_rate: {test: /^(-?\d{1,2}(\.\d{1,3})?)?$/},
          apply_newin_operate_rate: {test: /^(-?\d{1,2}(\.\d{1,3})?)?$/},
          apply_sale_partner_rate: {test: /^(-?\d{1,2}(\.\d{1,3})?)?$/},
          apply_newin_partner_rate: {test: /^(-?\d{1,2}(\.\d{1,3})?)?$/},
        },
        dynamicPricing: {
          shop_id: {test: /^\d{1,5}$/, type: 'number', required: true},
          dynamicPricingEnabled: {test: /^(true|false)$/i, type: 'boolean'},
          dpCondBalaanRatio: {test: /^(\d{1,3}(\.\d{1,3})?)?$/},
          dpPositiveBaseRate: {test: /^(\d{1,2}(\.\d{1,3})?)?$/},
          dpPositiveCondRate: {test: /^(\d{1,2}(\.\d{1,3})?)?$/},
          dpPositiveBalaanRate: {test: /^(\d{1,2}(\.\d{1,3})?)?$/},
          dpNegativeBaseRate: {test: /^(\d{1,2}(\.\d{1,3})?)?$/},
          dpNegativeCondRate: {test: /^(\d{1,2}(\.\d{1,3})?)?$/},
          dpNegativeBalaanRate: {test: /^(\d{1,2}(\.\d{1,3})?)?$/},
          dpMinRate: {test: /^(\d{1,2}(\.\d{1,3})?)?$/},
        },
        partnerGradeSetting: {
          shop_id: {test: /^\d{1,5}$/, type: 'number', required: true},
          partnerGrade: {test: /^(none|smart|premium|normal|starter1|starter2)$/, type: 'string'},
        },
        catalogSetting: {
          shop_id: {test: /^\d{1,5}$/, type: 'number', required: true},
          goodsHealthRating: {test: /^[A-D]$/, type: 'string', required: true}
        },
      },
      htbConfig: {
        mdId: {
          fields: [
            {key: 'shop_id'},
            {key: 'md_id'},
          ]
        },
        operatePartnerRate: {
          fields: [
            {key: 'shop_id'},
            {key: 'partnerGrade'},
            {key: 'apply_sale_operate_rate'},
            {key: 'apply_newin_operate_rate'},
            {key: 'apply_sale_partner_rate'},
            {key: 'apply_newin_partner_rate'},
          ]
        },
        dynamicPricing: {
          fields: [
            {key: 'shop_id'},
            {key: 'dynamicPricingEnabled'},
            {key: 'dpCondBalaanRatio'},
            {key: 'dpPositiveBaseRate'},
            {key: 'dpPositiveCondRate'},
            {key: 'dpPositiveBalaanRate'},
            {key: 'dpMinRate'},
            {key: 'dpNegativeBaseRate'},
            {key: 'dpNegativeCondRate'},
            {key: 'dpNegativeBalaanRate'},
          ]
        },
        partnerGradeSetting: {
          fields: [
            {key: 'shop_id'},
            {key: 'partnerGrade'}
          ]
        },
        catalogSetting: {
          fields: [
            {key: 'shop_id'},
            {key: 'goodsHealthRating'},
          ]
        },

      }
    }
  },
  watch: {
    tabIndex(v) {
      setTimeout(() => this.$refs['htb' + v].hotInstance.render(), 0);
    },
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('change', v);
      }
    }
  },
  methods: {
    async downXlsx(type) {
      const shopIds = this.form.shop.map(e => e.shop_id);
      if (!shopIds.length) return alert('다운받을 SHOP 을 선택해주세요');

      const fields = this.xlsxFields[type].split(',');
      this.busy[type] = true;
      const j = await this.$api.postJson('/shop/down', {shopIds, fields});
      this.busy[type] = false;
      if (j) {
        down(j.list, null, fields, `Shop_${type}_${this.$utils.dt()}`, 'xlsx');
      }
    },
    async handleXlsx(event) {
      const file = (event.dataTransfer || event.target).files[0];
      if (!file || !file.name.endsWith('xlsx') && !file.name.endsWith('xls')) return this.$utils.alert('xlsx 파일을 업로드해주세요');

      const {headers, rows} = await readXlsx(file);
      const type = event.target.dataset.type;
      this.uploadXlsx(event.target, headers, rows, type);
    },
    async uploadXlsx(target, headers, rows, type) {
      const vMap = this.validator[type];

      const unknown = headers.filter(e => !vMap[e]);
      if (unknown.length) return alert('알 수 없는 컬럼들이 있습니다:\n' + unknown.join('\n'));

      const required = Object.keys(vMap).filter(e => !headers.includes(e));
      if (required.length) return alert('필수 컬럼이 빠져있습니다:\n' + required.join('\n'));

      const wrongRows = [];
      rows.forEach((row, i) => {
        const wrongCols = [];
        headers.forEach(h => {
          const tester = vMap[h].test;
          if ((row[h] != null && row[h] !== '') && tester && !tester.test(row[h])) {
            wrongCols.push(`${h}: ${row[h]}`);
          }
          if ((row[h] == null || row[h] === '') && vMap[h].required) {
            wrongCols.push(`${h}: (비어있음)`);
          }
        });
        if (wrongCols.length) wrongRows.push({idx: i, cols: wrongCols});
      });
      if (wrongRows.length) return alert('다음 컬럼들의 값이 올바르지 않습니다:\n' + wrongRows.map(e => `${e.idx + 1} 번째줄 ${e.cols.map(e => e).join(', ')}`).join('\n'));

      // 컬럼 정의에 type 이 있는 경우 해당 타입으로 casting 한다.
      const typeHeaders = headers.filter(h => vMap[h].type);
      rows.forEach(row => {
        typeHeaders.forEach(h => {
          const type = vMap[h].type;
          if (type === 'number') {
            row[h] = +row[h];
          } else if (type === 'string') {
            row[h] = '' + row[h];
          } else if (type === 'boolean') {
            if (row[h] && typeof row[h] !== 'boolean') {
              if (row[h].match(/^true$/i)) {
                row[h] = true;
              } else if (row[h].match(/^false$/i)) {
                row[h] = false;
              } else {
                return alert('TRUE / FALSE 값이 맞게 입력되었는지 확인해주세요: ' + row[h]);
              }
            }
          }
        });
      });

      this.busy[type] = true;
      const j = await this.$api.postJson('/shop/uploadShop', {type, rows});
      this.busy[type] = false;
      if (j.ok === 1) {
        this.$utils.alert(`${j.cnt} 건 정상적으로 업로드 되었습니다`);
        this.$emit('refreshList');
        this.show = false;
      } else if (j.ok === -1) {
        this.$modal.show({title: '업로드 에러 확인', html: '<pre>' + `<h4>${j.msg}</h4>` + '</pre>'});
      }
      target.value = "";
    }
  }
}
</script>
