<script>
export default {
  name: 'shopBrandGuide',
  model: {prop: 'value', event: 'change'},
  props: {
    value: {type: Boolean, require: true},
    type: {type: String, default: 'brandWhitelist', require: true}
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('change', v);
      }
    }
  },
  methods: {
    async addBrandNext() {
      localStorage.setItem('popup:shopBrandFirstSet', this.$moment().add(30, 'day').valueOf());
      this.$emit('set-brand-list', this.type);
    },
  }
}
</script>

<template>
  <div>
    <b-modal title="판매 가능 브랜드 추가 안내사항" size="lg" v-model="show"
             ok-title="이해했습니다.계속 진행하겠습니다." cancel-title="다음에 진행하겠습니다" @ok="addBrandNext()">
      <b-alert variant="warning" show>
        판매 가능/제한 브랜드 설정을 이용하기 전에, 아래의 주의사항을 확인해주세요.
      </b-alert>
      <ul>
        <li>판매 가능 브랜드의 추가 / 삭제는 파트너의 요청이 있거나, 협의를 통해서만 가능합니다.</li>
        <li>해당 브랜드에 상품이 등록될 경우, 브랜드를 삭제하게 되면 상품도 삭제됩니다.</li>
        <li>판매 가능 / 제한 설정은 한 가지 기능만 사용 가능합니다.</li>
        <li>판매 가능으로 한 개 이상의 브랜드가 설정된다면, 파트너센터 및 API 에서 해당 브랜드의 상품만 등록 가능해집니다.</li>
        <li>판매 제한으로 한 개 이상의 브랜드가 설정된다면, 파트너센터 및 API 에서 해당 브랜드의 상품 등록이 불가합니다.</li>
      </ul>
    </b-modal>
  </div>
</template>

<style scoped>

</style>
