<template>
  <b-row class="text-center my-1">
    <b-col>
      <label class="col-form-label">
        {{name}}
        <i v-if="tooltip" class="fa fa-question-circle ml-1" v-b-tooltip="tooltip"></i>
      </label>
    </b-col>
    <template v-for="f in fields">
      <b-col :key="f">
        <b-form-radio-group class="col-form-label" v-model="item[f]" :options="options"></b-form-radio-group>
      </b-col>
    </template>
  </b-row>
</template>

<script>
export default {
  name: 'PriceTableRadio',
  model: {prop: 'value', event: 'change'},
  props: {
    value: Object,
    name: String,
    field: String,
    options: Array,
    tooltip: String,
  },
  data() {
    return {
      prices: ['consumer', 'sale', 'newin', 'supply'],
      fields: []
    }
  },
  async created() {
    this.fields = this.prices.map(price => this.field.replace('$price', price));
  },
  computed: {
    item: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('change', v);
      }
    },
  },
  methods: {

  },
}
</script>
