<script>
export default {
  name: 'removeShopBrand',
  model: {prop: 'value', event: 'change'},
  props: {
    value: {type: Boolean, require: true},
    type: {type: String, require: true},
    shopId: {type: Number, require: true},
    brand: {type: Object, default: () => {
      return {
        brandNo: null,
        brandNm: null
      }
    }, require: true}
  },
  data() {
    return {
      finalCheck: false,
      mappedCount: 0,
      mallCount: 0,
      busy: {
        removeBrand: false,
        brandStat: false,
      }
    }
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('change', v);
      }
    }
  },
  methods: {
    async initModal() {
      this.finalCheck = false;
      this.busy.brandStat = true;
      const j = await this.$api.postJson('/shop/brandStat', {shopId: this.shopId, brandNo: this.brand.brandNo});
      this.busy.brandStat = false;
      if (j) {
        this.mappedCount = j.mappedCount;
        this.mallCount = j.mallCount;
      }
    },
    async removeBrand() {
      this.busy.removeBrand = true;
      const endpoint = this.type === 'brandWhitelist' ? 'removeBrandWhitelist' : 'removeBrandBlackList';
      const j = await this.$api.postJson(`/shop/${endpoint}`, {shopId: this.shopId, brandNo: this.brand.brandNo});
      this.busy.removeBrand = false;
      if (j) {
        this.$utils.alert('삭제되었습니다');
        this.$emit('remove-brand-list', this.brand.brandNo);
      }
    },
  }
}
</script>

<template>
  <div v-if="show">
    <b-modal :title="`판매 ${type === 'brandWhitelist' ? '가능' : '제한'} 브랜드 관리(삭제)`" size="lg" v-model="show" @show="initModal" @ok="removeBrand">
      <h6>[브랜드 삭제 시]</h6>
      <ul>
        <li>판매 가능 브랜드 삭제는 <span class="text-danger">파트너의 요청에 의해서만 삭제가 가능</span>하며, 그 외의 경우 <span class="text-danger">반드시 파트너와의 협의가 필요</span>합니다.</li>
        <li v-if="type === 'brandWhitelist'">파트너의 해당 브랜드 상품은 <span class="text-danger">삭제되며 원복되지 않습니다.</span> 동일 브랜드를 다시 추가해도 마찬가지 입니다.</li>
        <li v-if="type === 'brandWhitelist'">파트너센터 / API 에서 <span class="text-danger">해당 브랜드의 상품 등록이 불가합니다.</span></li>
      </ul>
      <div v-if="type === 'brandWhitelist'">
        <h6>[현재 브랜드와 연관된 데이터]</h6>
        <div v-if="busy.brandStat" class="text-center">
          <b-spinner variant="success"></b-spinner>
        </div>
        <template v-else>
          <b-row>
            <b-col class="text-center">
              <small>Mapped(원본상품)</small>
              <div class="fs-20">{{mappedCount}}</div>
            </b-col>
            <b-col class="text-center">
              <small>발란몰 Live 상품</small>
              <div class="fs-20">{{mallCount}}</div>
            </b-col>
          </b-row>
        </template>
      </div>
      <div class="mt-3">
        <b-form-checkbox class="text-danger" v-model="finalCheck">
          위 유의사항을 숙지했으며, [{{brand.brandNo}}. {{brand.brandNm}}] 브랜드 삭제를 진행하겠습니다.
        </b-form-checkbox>
      </div>
      <template v-slot:modal-footer="{ok, cancel}">
        <b-button variant="danger" @click="ok()" :disabled="!finalCheck || busy.removeBrand">
          진행
          <b-spinner class="ml-1" small v-if="busy.removeBrand"></b-spinner>
        </b-button>
        <b-button variant="secondary" @click="cancel()">
          닫기
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<style scoped>

</style>
