<template>
  <b-row class="simulate my-1" :class="$attrs.class">
    <b-col>
      <label class="col-form-label">
        {{name}}
        <i v-if="tooltip" class="fa fa-question-circle ml-1" v-b-tooltip="tooltip"></i>
      </label>
    </b-col>
    <template v-for="p in prices">
      <b-col :key="p">
        <label class="col-form-label">
          {{ pre }}
          <template v-if="type === 'rnc2'">{{ $utils.rnc(sCalc[p][field], 2) }}</template>
          <template v-else-if="type === 'rnc'">{{ $utils.rnc(sCalc[p][field]) }}</template>
          <template v-else-if="type === 'prRule'">
            {{ sCalc.prRule[p === 'consumer' ? p : p === 'supply' ? 'price': 'final_sale_price'] ? $utils.rnc(sCalc.prRule[p === 'consumer' ? p : p === 'supply' ? 'price': 'final_sale_price']) : '-' }}
          </template>
          <template v-else-if="type === 'rebate'">{{ p === 'supply' ? '- ' + $utils.rnc(sCalc.rebate) + ' 원' : '' }}</template>
          <template v-else></template>
          {{ post }}
        </label>
      </b-col>
    </template>
  </b-row>
</template>

<script>
export default {
  name: 'PriceTableLabel',
  props: {
    sCalc: Object,
    name: String,
    field: String,
    type: String,
    pre: String,
    post: String,
    tooltip: String,
  },
  data() {
    return {
      prices: ['consumer', 'sale', 'newin', 'supply'],
    }
  },
  async created() {
  },
  methods: {
  },
}
</script>

<style scoped>
.simulate {
  text-align: right;
  background-color: #f2f2f2
}
.simulate.simulate-white-bg {
  background-color: #fff
}

.simulate .col:first-child {
  text-align: center;
}
</style>
