<template>
  <b-row class="text-center my-1">
    <b-col>
      <label class="col-form-label">
        {{name}}
        <i v-if="tooltip" class="fa fa-question-circle ml-1" v-b-tooltip="tooltip"></i>
      </label>
    </b-col>
    <template v-for="(f, idx) in fields">
      <b-col :key="f">
        <b-input-group>
          <b-form-input class="text-center" v-model.number="item[f]" :readonly="readonly && readonly[readonlyKey] && readonlyIndex.includes(idx)"></b-form-input>
          <b-input-group-append v-if="append === 'percent'">
            <b-input-group-text><i class="fa fa-percent"></i></b-input-group-text>
          </b-input-group-append>
        </b-input-group>
      </b-col>
    </template>
<!--    <b-col>
      <b-input-group>
        <b-form-input class="text-center" v-model.number="item.apply_sale_rate"></b-form-input>
        <b-input-group-append>
          <b-input-group-text><i class="fa fa-percent"></i></b-input-group-text>
        </b-input-group-append>
      </b-input-group>
    </b-col>
    <b-col>
      <b-input-group>
        <b-form-input class="text-center" v-model.number="item.apply_newin_rate" :readonly="readonly.parallel"></b-form-input>
        <b-input-group-append>
          <b-input-group-text><i class="fa fa-percent"></i></b-input-group-text>
        </b-input-group-append>
      </b-input-group>
    </b-col>
    <b-col>
      <b-input-group>
        <b-form-input class="text-center" v-model.number="item.apply_supply_rate"></b-form-input>
        <b-input-group-append>
          <b-input-group-text><i class="fa fa-percent"></i></b-input-group-text>
        </b-input-group-append>
      </b-input-group>
    </b-col>-->
  </b-row>
</template>

<script>
export default {
  name: 'PriceTableInput',
  model: {prop: 'value', event: 'change'},
  props: {
    value: Object,
    name: String,
    field: String,
    append: String,
    readonly: Object,
    readonlyKey: String,
    readonlyIndex: Array,
    tooltip: String,
  },
  data() {
    return {
      prices: ['consumer', 'sale', 'newin', 'supply'],
      fields: []
    }
  },
  async created() {
    this.fields = this.prices.map(price => this.field.replace('$price', price));
  },
  computed: {
    item: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('change', v);
      }
    },
  },
  methods: {

  },
}
</script>
