<script>
  export default {
    name: 'shopBrandSet',
    model: {prop: 'value', event: 'change'},
    props: {
      value: {type: Boolean, require: true},
      type: {type: String, require: true},
      item: {type: Object, require: true}
    },
    computed: {
      show: {
        get() {
          return this.value;
        },
        set(v) {
          this.$emit('change', v);
        }
      }
    },
    data() {
      return {
        selectedBrand: null,
        busy: {
          addBrand: false,
        }
      }
    },
    methods: {
      async addListedBrand() {
        if (this.selectedBrand == null) return alert('브랜드를 선택해주세요');
        const brandNo = this.selectedBrand.brand_no; // b-form-select 의 경우 value 값이 바로 탑재
        this.item[this.type] = this.item[this.type] || [];
        if (this.item[this.type].map(e => e.brand_no).includes(brandNo)) {
          return alert('이미 선택된 브랜드입니다.');
        }

        this.busy.addBrand = true;
        const endpoint = this.type === 'brandWhitelist' ? 'addBrandWhitelist' : 'addBrandBlackList';
        const j = await this.$api.postJson(`/shop/${endpoint}`, {shopId: this.item.shop_id, brandNo});
        this.busy.addBrand = false;
        if (j) {
          this.$utils.alert('추가되었습니다');
          this.$emit('add-brand-list', brandNo);
        }
      },
    }
  }
</script>

<template>
  <div>
    <b-modal :title="`판매 ${type === 'brandWhitelist' ? '가능' : '제한'} 브랜드 선택`" size="lg" v-model="show" @ok="addListedBrand">
      <b-alert variant="warning" show>
        {{ type === 'brandWhitelist' ? '해당 브랜드만 파트너센터를 통해 등록 및 판매할 수 있습니다.' : '해당 브랜드는 파트너센터를 통해 등록 및 판매가 제한됩니다.' }}
      </b-alert>
      <brand-preset v-model="selectedBrand" :hideButtons="true" :hideOptions="true" :hideDisabled="true" :multiple="false"></brand-preset>
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button variant="primary" @click="ok()">
          <b-spinner class="ml-1" small v-if="busy.addBrand"></b-spinner>
          추가
        </b-button>
        <b-button variant="secondary" @click="cancel()">
          취소
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<style scoped>

</style>
