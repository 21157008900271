<template>
  <div>
    <div class="title-sm">배송정보</div>
    <b-row class="mb-2">
      <b-col cols="3">
        <small>오늘의픽업 MallId <i class="fa fa-question" v-b-tooltip="'오늘의픽업 당일배송에 사용되는 상점 아이디'"
                               @click="$utils.open('https://admin.todaypickup.com/')"></i></small><br/>
        <b-form-input v-model="item.todaypickup_mall_id"></b-form-input>
      </b-col>
      <b-col cols="3">
        <small>오늘의픽업 ID <i class="fa fa-question" v-b-tooltip="'오늘의픽업 당일배송에 사용되는 아이디'"
                               @click="$utils.open('https://admin.todaypickup.com/')"></i></small><br/>
        <b-form-input v-model="item.todaypickup_id"></b-form-input>
      </b-col>
      <b-col cols="3">
        <small>오늘의픽업 PW <i class="fa fa-question" v-b-tooltip="'오늘의픽업 당일배송에 사용되는 비밀번호'"></i></small><br/>
        <b-form-input v-model="item.todaypickup_pw"></b-form-input>
      </b-col>
      <b-col cols="3">
        <small>오늘의픽업 webhookKey <i class="fa fa-question" v-b-tooltip="'오늘의픽업 당일배송에 사용되는 webhook 키'"></i></small><br/>
        <b-form-input v-model="item.todaypickup_webhook"></b-form-input>
      </b-col>
    </b-row>

    <div class="title-sm">연동정보</div>
    <b-row>
      <b-col cols="3">
        <small>연동원천 주소</small>
        <b-form-input v-model="item.link_url"></b-form-input>
      </b-col>
      <b-col cols="3">
        <small>연동 ID</small>
        <b-form-input v-model="item.link_id"></b-form-input>
      </b-col>
      <b-col cols="3">
        <small>연동 PW</small>
        <b-form-input v-model="item.link_pw"></b-form-input>
      </b-col>
    <b-row>
    </b-row>
      <b-col cols="12">
        <small>연동관련 정보</small>
        <b-textarea v-model="item.link_desc"></b-textarea>
      </b-col>
    </b-row>
    <small>기타 항목 <i class="fa fa-plus text-primary pointer" @click="addLinkItem"></i></small>
    <b-row>
      <b-col cols="3">
        <small>Name</small>
      </b-col>
      <b-col cols="8">
        <small>Value</small>
      </b-col>
    </b-row>
    <b-row v-for="(i, idx) in item.link_items" :key="idx">
      <b-col cols="3">
        <b-form-input v-model="i.name"></b-form-input>
      </b-col>
      <b-col cols="8">
        <b-form-input v-model="i.value"></b-form-input>
      </b-col>
      <b-col cols="1">
        <i class="fa fa-close text-danger" @click="removeLinkItem(idx)"></i>
      </b-col>
    </b-row>
    <div class="mb-2"></div>


<!--    <div class="title-sm">SmartStore Address</div>
    <b-row class="mb-2" v-if="item._ss">
      <b-col cols="6">
        <small>Name</small>
        <b-form-input v-model="item._ss.Name" readonly></b-form-input>
      </b-col>
      <b-col cols="3">
        <small>AddressId</small>
        <b-form-input v-model="item._ss.AddressId" readonly></b-form-input>
      </b-col>
      <b-col cols="3">
        <small>PostalCode</small>
        <b-form-input v-model="item._ss.PostalCode" readonly></b-form-input>
      </b-col>
      <b-col cols="6">
        <small>BaseAddress</small>
        <b-form-input v-model="item._ss.BaseAddress" readonly></b-form-input>
      </b-col>
      <b-col cols="6">
        <small>DetailAddress</small>
        <b-form-input v-model="item._ss.DetailAddress" readonly></b-form-input>
      </b-col>
      <b-col cols="12">
        <small>FullAddress</small>
        <b-form-input v-model="item._ss.FullAddress" readonly></b-form-input>
      </b-col>
      <b-col cols="6">
        <small>PhoneNumber1</small>
        <b-form-input v-model="item._ss.PhoneNumber1" readonly></b-form-input>
      </b-col>
      <b-col cols="6">
        <small>PhoneNumber2</small>
        <b-form-input v-model="item._ss.PhoneNumber2" readonly></b-form-input>
      </b-col>
    </b-row>
    <div v-else>
      등록된 주소지가 없습니다.
    </div>-->

    <div class="title-sm">PartnerAPI Key</div>
    <div v-if="item._partner">
      ID: {{item._partner.id}}, APIKEY: {{item._partner.apikey}}
    </div>
    <div v-else>
      파트너센터 가입정보가 없습니다.
    </div>
  </div>
</template>

<script>
export default {
  name: 'ShopLink',
  model: {prop: 'value', event: 'change'},
  props: {
    value: Object,
  },
  data() {
    return {
      masked: true
    }
  },
  created() {
  },
  computed: {
    item: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('change', v);
      }
    },
  },
  methods: {
    addLinkItem() {
      this.item.link_items = this.item.link_items || [];
      this.item.link_items.push({name: '', value: ''});
      this.$forceUpdate();
    },
    removeLinkItem(idx) {
      this.item.link_items.splice(idx, 1);
      this.$forceUpdate();
    }
  }
}
</script>
