<template>
  <div>
    <b-dropdown id="dropdown-form" :variant="variant" :text="title" ref="dropdown" class="" :disabled="disabled">
      <b-dropdown-form style="width:500px">
        <b-row v-for="i in compGroup" :key="i.join('')">
          <b-col v-for="j in i" cols="4" :key="j">
            <b-dropdown-item @click="setCompany(j)">{{j}}</b-dropdown-item>
          </b-col>
        </b-row>
      </b-dropdown-form>
    </b-dropdown>
  </div>
</template>

<script>
  import {DELIVERY_COMP_PRESET, DELIVERY_COMP_PRESET_SAMYOUNG} from 'balaan_constants'
  export default {
    name: "DeliveryDropdown",
    props: ['title', 'variant', 'filter', 'disabled'],
    data() {
      return {
        compGroup: []
      };
    },
    async created() {
      let preset = DELIVERY_COMP_PRESET;
      if (this.filter === 'samyoung') preset = DELIVERY_COMP_PRESET_SAMYOUNG;

      for (let i=0; i<preset.length; i+=3) {
        this.compGroup.push(preset.slice(i, i+3));
      }
    },
    methods: {
      setCompany(comp) {
        this.$emit('setCompany', comp);
      },
    }
  }
</script>

<style scoped>

</style>
